import React from 'react';
import './Review.css'
import dealor1 from './Images/dealor-01.jpg'
import dealor2 from './Images/dealor-02.jpg'
import r1 from './Images/r1.png'
import r2 from './Images/r2.png'
import r3 from './Images/r3.png'
import r4 from './Images/r4.png'
const Reviews = () => {
    return (
        <>
            <center className="mt-5">
                <h1 style={{ color: "#1A5257" }}>Testimonials</h1>
                <h5 style={{ color: "#931B1F", fontSize: "16px" }}>Our Passion Is People. Whats yours ?</h5>
            </center>
            <div className="container-fluid mb-5 justify-content-center">
                <div className=" row justify-content-center ">
                    <div className=" col-sm-6 col-lg-3 col-12  ">
                        <div className=" cart-itt card mt-3 ">
                            <div className="card-header bg-white text-center border-0 ">
                                <div className="row justify-content-center ">
                                    <div className="col"> <img className="align-self-center text-center  outside img-fluid" src={r1} width="70px" height="30px" /> </div>
                                </div>
                                <div className="row text-center name">
                                    <div className="col">
                                        <h5 className="mb-0 profile-pic">Aleem ul Hassan</h5>
                                        <p><span className="fa fa-star mr-1"></span><span className="fa fa-star mr-1"></span><span className="fa fa-star mr-1"></span><span className="fa fa-star mr-1"></span><span className="fa fa-star-o mr-1"></span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0 text-center pb-3 ">
                                <div className="row justify-content-center">
                                    <div className="col-md-8 col">
                                        <p style={{ textAlign: "justify" }} className="bold"> <span><img className="img-fluid quotes" src="https://i.imgur.com/U20aFIt.png" width="20" height="20" />
                                        </span> One of the well managed housing society of Chiniot <span>
                                            <img className="img-fluid quotes-down" src="https://i.imgur.com/DPzmyiD.png" width="20" height="20" /> </span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-center bg-white border-0 mt-0 pt-0">
                                <div className="row">
                                    <div className="col"><span className="mx-2"><i className="fa fa-twitter" aria-hidden="true"></i></span><span className="mx-2"><i className="fa fa-facebook" aria-hidden="true"></i></span><span className="mx-2"><i className="fa fa-instagram" aria-hidden="true"></i></span><span className="mx-2"><i className="fa fa-pinterest-p" aria-hidden="true"></i></span><span className="mx-2"><i className="fa fa-google-plus" aria-hidden="true"></i></span></div>
                                </div>
                                <hr className="my-1" />
                            </div>
                        </div>
                    </div>
                    <div className=" col-sm-6 col-lg-3 col-12 ">
                        <div className=" cart-itt card mt-3">
                            <div className="card-header bg-white text-center border-0 ">
                                <div className="row justify-content-center ">
                                    <div className="col"> <img className="align-self-center text-center  outside img-fluid" src={r2} width="70px" height="30px" /> </div>
                                </div>
                                <div className="row text-center name">
                                    <div className="col">
                                        <h5 className="mb-0 profile-pic">waq karim</h5> 
                                        <p><span className="fa fa-star mr-1"></span><span className="fa fa-star mr-1"></span><span className="fa fa-star mr-1"></span><span className="fa fa-star mr-1"></span><span className="fa fa-star mr-1"></span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0 text-center pb-3 ">
                                <div className="row justify-content-center">
                                    <div className="col-md-8 col">
                                        <p style={{ textAlign: "justify" }} className="bold"> <span><img className="img-fluid quotes" src="https://i.imgur.com/U20aFIt.png" width="20" height="20" />
                                        </span> Full security coverage <span>
                                            <img className="img-fluid quotes-down" src="https://i.imgur.com/DPzmyiD.png" width="20" height="20" /></span> </p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-center bg-white border-0 mt-0 pt-0">
                                <div className="row">
                                    <div className="col"> <span className="mx-2"><i className="fa fa-twitter" aria-hidden="true"></i></span><span className="mx-2"><i className="fa fa-facebook" aria-hidden="true"></i></span><span className="mx-2"><i className="fa fa-instagram" aria-hidden="true"></i></span><span className="mx-2"><i className="fa fa-pinterest-p" aria-hidden="true"></i></span><span className="mx-2"><i className="fa fa-google-plus" aria-hidden="true"></i></span> </div>
                                </div>
                                <hr className="my-1" />
                            </div>
                        </div>
                    </div>
                    <div className=" col-sm-6 col-lg-3 col-12 ">
                        <div className=" cart-itt card mt-3">
                            <div className="card-header bg-white text-center border-0 ">
                                <div className="row justify-content-center ">
                                    <div className="col"> <img className="align-self-center text-center  outside img-fluid" src={r3} width="70px" height="30px" /> </div>
                                </div>
                                <div className="row text-center name">
                                    <div className="col">
                                        <h5 className="mb-0 profile-pic">Bilal Aarbi</h5> 
                                        <p><span className="fa fa-star mr-1"></span><span className="fa fa-star mr-1"></span><span className="fa fa-star-o mr-1"></span><span className="fa fa-star-o mr-1"></span><span className="fa fa-star-o mr-1"></span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0 text-center pb-3 ">
                                <div className="row justify-content-center">
                                    <div className="col-md-8 col">
                                        <p style={{ textAlign: "justify" }} className="bold"> <span><img className="img-fluid quotes" src="https://i.imgur.com/U20aFIt.png" width="20" height="20" />
                                        </span> Good society in chiniot city very safe and very clean area <span>
                                            <img className="img-fluid quotes-down" src="https://i.imgur.com/DPzmyiD.png" width="20" height="20" /> </span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-center bg-white border-0 mt-0 pt-0">
                                <div className="row">
                                    <div className="col"> <span className="mx-2"><i className="fa fa-twitter" aria-hidden="true"></i></span><span className="mx-2"><i className="fa fa-facebook" aria-hidden="true"></i></span><span className="mx-2"><i className="fa fa-instagram" aria-hidden="true"></i></span><span className="mx-2"><i className="fa fa-pinterest-p" aria-hidden="true"></i></span><span className="mx-2"><i className="fa fa-google-plus" aria-hidden="true"></i></span> </div>
                                </div>
                                <hr className="my-1" />
                            </div>
                        </div>
                    </div>
                    <div className=" col-sm-6 col-lg-3 col-12 ">
                        <div className=" cart-itt card mt-3">
                            <div className="card-header bg-white text-center border-0 ">
                                <div className="row justify-content-center ">
                                    <div className="col"> <img className="align-self-center text-center  outside img-fluid" src={r4} width="70px" height="30px" /> </div>
                                </div>
                                <div className="row text-center name">
                                    <div className="col">
                                        <h5 className="mb-0 profile-pic">Waseem Akram</h5> 
                                        <p><span className="fa fa-star mr-1"></span><span className="fa fa-star mr-1"></span><span className="fa fa-star-o mr-1"></span><span className="fa fa-star-o mr-1"></span><span className="fa fa-star-o mr-1"></span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0 text-center pb-3 ">
                                <div className="row justify-content-center">
                                    <div className="col-md-8 col">
                                        <p style={{ textAlign: "justify" }} className="bold"> <span><img className="img-fluid quotes" src="https://i.imgur.com/U20aFIt.png" width="20" height="20" />
                                        </span> Because I live near by <span>
                                            <img className="img-fluid quotes-down" src="https://i.imgur.com/DPzmyiD.png" width="20" height="20" /> </span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-center bg-white border-0 mt-0 pt-0">
                                <div className="row">
                                    <div className="col"> <span className="mx-2"><i className="fa fa-twitter" aria-hidden="true"></i></span><span className="mx-2"><i className="fa fa-facebook" aria-hidden="true"></i></span><span className="mx-2"><i className="fa fa-instagram" aria-hidden="true"></i></span><span className="mx-2"><i className="fa fa-pinterest-p" aria-hidden="true"></i></span><span className="mx-2"><i className="fa fa-google-plus" aria-hidden="true"></i></span> </div>
                                </div>
                                <hr className="my-1" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Reviews;