import React from 'react';
import './LayoutPlan.css'
import Mosque from './Images/Mosque.jpg'
import swimmingpooi from './Images/swimmingpool.jpg'
import squash from './Images/squash.jpg'
import Commercial from './Images/Commercial.jpg'
import gym from './Images/gym.jpg'
import ModalImage from "react-modal-image";

const Amenities = () => {
    return (
        <>
            <div className='amenities' >
                <center>
                    <h1 style={{ color: "#1A5257" }}>AMENITIES</h1>
                    <h5 style={{ color: "#931B1F", fontSize: "16px" }}>"Search. See. Love"</h5>
                </center>
                <div className='main-image-sec'>
                    <div className='first-sec '>
                        <ModalImage
                            small={Mosque}
                            large={Mosque}
                            alt="Masjid"
                        />
                        {/* <img src={Mosque} /> */}

                    </div>
                    <div className='second-sec'>
                        <div className='first-row'>
                            <div className='swimi1'>
                                <ModalImage
                                    small={swimmingpooi}
                                    large={swimmingpooi}
                                    alt="Swimming Pool"
                                />

                            </div>
                            <div className='swimi2'>
                                <ModalImage
                                    small={squash}
                                    large={squash}
                                    alt="Squash Ground"
                                />
                            </div>

                        </div>
                        <div className='second-row'>
                            <div className='swimi3'>
                                <ModalImage
                                    small={Commercial}
                                    large={Commercial}
                                    alt="Commercial Area"
                                />

                            </div>
                            <div className='swimi4'>
                                <ModalImage
                                    small={gym}
                                    large={gym}
                                    alt="Gym"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Amenities;