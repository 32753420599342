import React from 'react';
import './Navbar.css'
import { FaFacebook, FaYoutube } from 'react-icons/fa';
import { AiFillTwitterCircle, AiFillInstagram } from 'react-icons/ai';
import { IoMdCall } from 'react-icons/io';
import instalogo from './Images/InstaLogo.jpg'

const Navbar = () => {
    return (
        <>
            <div   className=' main-divvv my-3 d-flex justify-content-center align-items-center '>
                <div className=' social-div '>
                    <a className='text-light text-decoration-none fs-6 d-flex justify-content-center align-items-center' href='https://www.facebook.com/DinGardensPk/' target='_blank' >
                        <FaFacebook size="24px" color='#4267B2' />
                    </a>
                    <a className='text-light text-decoration-none fs-6 d-flex justify-content-center align-items-center' href='https://twitter.com/DinGardens' target='_blank' >
                        <AiFillTwitterCircle size="26px" color='#00acee' />
                    </a>
                    <a className='text-light text-decoration-none fs-6 d-flex justify-content-center align-items-center' href='https://www.instagram.com/dingardens/' target='_blank' >
                        {/* <AiFillInstagram size="20px" color /> */}
                        <img src={instalogo} width="20px" height="20px" />
                    </a>
                    <a className='text-light text-decoration-none fs-6 d-flex justify-content-center align-items-center' href='https://www.youtube.com/channel/UCCZPhVuHxv_ADrXroYrHPKA/videos' target='_blank' >
                        <FaYoutube size="24px" color='red' />
                    </a>
                </div>
                <div className=" logo-div " >
                    <img src='http://www.dinproperties.com.pk/images/logo.png' />
                </div>
                <div className=' get-div'>
                    <a style={{ color: "#921a1f" }} className=' text-decoration-none fs-6 d-flex justify-content-center align-items-center gap-1' href='#' >
                        <IoMdCall size='16px' color='#921a1f' />
                        042-111-111346
                    </a>
                    <button type='button'  className="btn  rounded-1">
                        Get In Touch
                    </button>

                </div>


            </div>
            <ul style={{backgroundColor:"#921a1f"}} className="nav justify-content-center text-capitalize ">
                <li className="nav-item">
                    <a style={{ color: "white" }} className="nav-link" href="#">home</a>
                </li>
                <li className="nav-item">
                    <a style={{ color: "white" }} className="nav-link" href="#">about us</a>
                </li>
                <li className="nav-item">
                    <a style={{ color: "white" }} className="nav-link" href="#">by laws</a>
                </li>
                <li className="nav-item">
                    <a style={{ color: "white" }} className="nav-link" href="#"> Properties</a>
                </li>
                <li className="nav-item">
                    <a style={{ color: "white" }} className="nav-link" href="#"> payment plan</a>
                </li>
                <li className="nav-item">
                    <a style={{ color: "white" }} className="nav-link" href="#"> Amenities</a>
                </li>
                <li className="nav-item">
                    <a style={{ color: "white" }} className="nav-link" href="#">dealers</a>
                </li>
                <li className="nav-item">
                    <a style={{ color: "white" }} className="nav-link" href="#"> Media</a>
                </li>
                <li className="nav-item">
                    <a style={{ color: "white" }} className="nav-link" href="#"> Contact</a>
                </li>



            </ul>
        </>
    );
}

export default Navbar;