import React from 'react';
import AboutDinPro from '../Components/AboutDinPro';
import Amenities from '../Components/Amenities';
import Footer from '../Components/Footer';
import HouseSlider from '../Components/HouseSlider';
import LayoutPlan from '../Components/LayoutPlan';
import Navbar from '../Components/Navbar';
import OurTeem from '../Components/OurTeam';
import Servieses from '../Components/Servieses';
import Slider from '../Components/Slider';
// import Webcam from 'react-webcam';
import Reviews from '../Components/Reviews';
import './HomePage.css'

const HomePage = () => {
    return (
        <>
            <Navbar />
            <Slider />
            <div className='main-contenerr'>
                <AboutDinPro />
                <HouseSlider />
                <Amenities />
                <Servieses />
                <LayoutPlan />
                <OurTeem />
                <Reviews />
            </div>
            <Footer />
        </>
    );
}

export default HomePage;