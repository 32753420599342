import React, { useEffect, useState } from "react";
import "./RithIcon.css";
import { FaWhatsapp } from "react-icons/fa";
import { IoIosArrowUp } from 'react-icons/io'


function RithIcon() {

    const [scrollY, setScrollY] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollY(position);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    }, [])
    // useEffect(() => {
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll)
    //     }
    // }, [])
    return (
        <>

            <div className="waicon" >
                <a style={{ fontSize: "40px" }} href="https://api.whatsapp.com/send/?phone=923088557555&text=Welcome&app_absent=0" target={"_blank"}
                    data-toggle="tooltip" data-placement="top" title="Contact For Any Query" >
                    <FaWhatsapp height="45px" width="45px" />
                </a>
            </div>

            <div className="arrowcon" style={{ display: scrollY > 100 ? 'flex' : 'none' }} >
                <IoIosArrowUp height="25px" width="25px" onClick={() => {
                    window.scrollTo(0, 0)
                }} />
            </div>
        </>
    );
}
export default RithIcon;