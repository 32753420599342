import React from 'react';
import './App.css';
import HomePage from './Pages/HomePage';
import RithIcon from './Components/RithIcon';

const App = () => {
  return (
    <>
    <HomePage />  
    <RithIcon />
    </>
  );
}

export default App;
