import React from 'react';
import { MdLocationOn, MdOutgoingMail } from 'react-icons/md';
import { IoMdCall } from 'react-icons/io';
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';
import FooterBg from './Images/footerbg.jpg'

const Footer = () => {
    return (
        <>
            <div style={{
                width: "100%", backgroundImage: `url(${FooterBg})`,
                backgroundRepeat: "no-repeat", backgroundAttachment: "fixed"
            }} className="mt-5" >
                <footer
                    className="text-center text-lg-start text-white " >
                    <div className="container p-4 pb-0">
                        <section className="">
                            <div style={{alignItems:"flex-start"}} className="row">
                                <div className="col-md-6 col-lg-6 col-xl-3 mx-auto mt-3 col-12 ">
                                    <h6 className="text-uppercase mb-4 font-weight-bold fs-4 ">
                                        Din Properties
                                    </h6>
                                    <p style={{ textAlign: "justify" }}>
                                        Din Properties is a recent venture of Din Industries aimed at providing quality real estate to our valued customers. With years of service, Din Properties is a name that you can trust.
                                    </p>
                                </div>

                                <hr className="w-100 clearfix d-md-none" />

                                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3 text-capitalize ">
                                    <h6 className="text-uppercase mb-4 font-weight-bold fs-4">OUR PAGES</h6>
                                    <div>
                                        <p className='mb-1'>
                                            <a herf="#" className="text-white text-decoration-none ">about us</a>
                                        </p>
                                        <p className='mb-1'>
                                            <a herf="#" className="text-white text-decoration-none ">contact us</a>
                                        </p>
                                        <p className='mb-1'>
                                            <a herf="#" className="text-white text-decoration-none ">Amenities</a>
                                        </p>
                                        <p className='mb-1'>
                                            <a herf="#" className="text-white text-decoration-none ">residential plan</a>
                                        </p>
                                        <p className='mb-1'>
                                            <a herf="#" className="text-white text-decoration-none ">payment plan</a>
                                        </p>
                                        <p className='mb-1'>
                                            <a herf="#" className="text-white text-decoration-none ">dealers</a>
                                        </p>
                                    </div>
                                </div>

                                <hr className="w-100 clearfix d-md-none" />

                                <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3 text-capitalize ">
                                    <h6 className="text-uppercase mb-4 font-weight-bold fs-4">
                                        PROPERTIES
                                    </h6>
                                    <div>
                                        <p className='mb-1'>
                                            <a herf="#" className="text-white text-decoration-none ">5 marla</a>
                                        </p>
                                        <p className='mb-1'>
                                            <a herf="#" className="text-white text-decoration-none ">7 marla</a>
                                        </p>
                                        <p className='mb-1'>
                                            <a herf="#" className="text-white text-decoration-none ">10 marla</a>
                                        </p>
                                        <p className='mb-1'>
                                            <a herf="#" className="text-white text-decoration-none ">1 kanal</a>
                                        </p>
                                        <p className='mb-1'>
                                            <a herf="#" className="text-white text-decoration-none ">commercial</a>
                                        </p>
                                        <p className='mb-1'>
                                            <a herf="#" className="text-white text-decoration-none ">by laws</a>
                                        </p>
                                    </div>
                                </div>

                                <hr className="w-100 clearfix d-md-none" />

                                <div style={{ textAlign: "justify" }} className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                                    <h6 className="text-uppercase mb-4 font-weight-bold fs-4">Contact</h6>
                                    <div>
                                        <p className='mb-1'><MdLocationOn /> Kacheri Chowk, 3.5 km Sargodha Faisalabad Road، Adjacent Superior Collage</p>
                                        <p className='mb-1 fs-6'><MdOutgoingMail /> info@dinproperties.com.pk</p>
                                        <p className='mb-1'><IoMdCall /> 042-111-111346</p>
                                    </div>

                                </div>
                            </div>
                        </section>

                        <hr className="my-3" />

                        <section className="p-3 pt-0">
                            <div className="row d-flex align-items-center">
                                <div className="col-md-7 col-lg-8 text-center text-md-start">
                                    <div className="p-3 ">
                                        © 2022 All Rights Reserved - Powered by
                                        <a herf="#" className="text-white text-decoration-none ms-2 fw-bold" href="https://www.softvalley.com.pk/"
                                        >Softvalley.
                                        </a
                                        >
                                    </div>
                                </div>
                                <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
                                    <a href='https://www.facebook.com/DinGardensPk/' target='_blank'
                                        className=" text-white m-3"

                                        role="button"
                                    ><FaFacebookF /></a>

                                    <a href='https://twitter.com/DinGardens' target='_blank'
                                        className=" text-white m-3"

                                        role="button"
                                    ><FaTwitter /></a>

                                    <a href='https://www.instagram.com/dingardens/' target='_blank'
                                        className=" text-white m-3"

                                        role="button"
                                    ><FaInstagram /></a>

                                    <a  href='https://www.youtube.com/channel/UCCZPhVuHxv_ADrXroYrHPKA/videos' target='_blank'
                                        className=" text-white  m-3 "
                                        role="button"
                                    ><FaYoutube /></a>
                                </div>
                            </div>
                        </section>
                    </div>
                </footer>
            </div>


        </>
    );
}

export default Footer;