import React from "react";
import PropTypes from "prop-types";
import './LayoutPlan.css';

const YoutubeEmbed = ({ embedId }) => (
  <div className="iframe-main">
    {/* <iframe  className="video-responsive"
     
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    /> */}
    <iframe  className="video-responsive"
    src="https://www.youtube.com/embed/4BEeKjL1umc?rel=0&autoplay=0&mute=0&playlist=4BEeKjL1umc&loop=1&controls=1" 
    frameborder="1" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen></iframe>
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;