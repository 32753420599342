import React from 'react';
import "./Servieses.css"
import img1 from "./Images/HHH-01.png"
import img2 from "./Images/HHH-02.png"
import img3 from "./Images/HHH-03.png"
import img4 from "./Images/HHH-04.png"


const Servieses = () => {
    return (
        <>
            <div className=' mt-5 mb-5 col-12' style={{textAlign: "center"}}>
            <center style={{marginTop:"50px"}}>
                <h1 style={{ color: "#1A5257" }}>Why People Believe in Us</h1>
                <h5 style={{ color: "#931B1F", fontSize:"16px" }}>Always render more and better service than is expected of you, no matter what your ask may be.</h5>
            </center>
              
                <div className="row" >
                    <div className="card col-sm-6 col-lg-3 col-12 ">
                        <div className="card-content">
                            <div  style={{width:"auto"}} className="card-body"> <img style={{width:"250px", height:"250px"}} alt="" src={img1} />
                                <div className="shadow"></div>
                                <div className="card-title"> 24/7 SECURITY</div>
                                <div className="card-subtitle">
                                    <p> <small className="text-muted"> The visionary approach of Din Properties ensures a secure lifestyle. Guarded entrance gates, vigilant security patrols and a boundary wall securing the whole society provide...</small> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" card col-sm-6 col-lg-3 col-12 ">
                        <div className="card-content">
                            <div className="card-body"> <img style={{width:"250px", height:"250px"}} src={img2} />
                                <div className="card-title">SHOP.DINE.REPEAT! </div>
                                <div className="card-subtitle">
                                    <p> <small className="text-muted"> In Din Gardens Commercial Area you can see the idea of mega shopping and leisure center turning into reality. Bringing you safe, easy and enjoyable shopping , where all...</small> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card col-sm-6 col-lg-3 col-12 ">
                        <div className="card-content">
                            <div className="card-body"> <img style={{width:"250px", height:"250px"}} src={img3}/>
                                <div className="card-title"> LAVISH LIFESTYLE </div>
                                <div className="card-subtitle">
                                    <p> <small className="text-muted">At Din Gardens, our development plans are formulated in accordance with the most advanced guidelines believing that basic necessities can be met in style...</small> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card col-sm-6 col-lg-3 col-12 ">
                        <div className="card-content">
                            <div className="card-body"> <img style={{width:"250px", height:"250px"}} src={img4} />
                                <div className="card-title"> FUN TIME</div>
                                <div className="card-subtitle">
                                    <p> <small className="text-muted">For all the young ones, unlimited fun awaits at theme park of Din gardens with various attractions, such as electric rides, food spots, various interesting games and many...</small> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Servieses;