import React from 'react';
import './LayoutPlan.css';
import YoutubeEmbed from './YoutubeEmbed';
import Layoutplan from './Images/Layout-Plan2.jpg'
// import ModalImage from "react-modal-image";
import { ImCross } from 'react-icons/im';
import { useState } from 'react';

const LayoutPlan = (event) => {
    const [modalv, setModalv] = useState("none");

    const popup = (e) => {
        if (e.currentTarget.id = "myImg")
            setModalv("block");
    }
    const closebtn = () => {
        setModalv("none");
    }
    const closebg = (e) => {
        if (e.target.tagName == "DIV") {
            setModalv("none");
        }
    }

    return (
        <>
            <div style={{ color: "#1A5257" }} className='my-4 d-flex justify-content-center'>
                <center>
                    <h1>LAYOUT PLAN</h1>
                    <h5 style={{ color: "#931B1F", fontSize: "16px" }}>Where Dreams Come Home.</h5>
                </center>
            </div>
            <div className='bg-image-sec'>
                <div className='discripation'>
                    <div className='text-divv'>
                        <h4> Where All Roads Leads To Homes</h4>
                        <p style={{ lineHeight: "30px" }}>Far from the madding crowd, yet so close to the vicinity of Chiniot, Din Gardens supreme location brings the best of both worlds. Enjoy serenity and seclusion along with sophistication and amenities of an exclusive lifestyle within the secure environs of a gated community!</p>
                    </div>
                </div>
                <div className='layoutplan'>

                    <img onClick={popup} src={Layoutplan} id="myImg" alt="Lay Out Plan" />
                </div>
                {/* The Modal  */}
                <div id="myModal" style={{ display: modalv }} className="modal" onClick={closebg} >
                    <span className="close" onClick={closebtn} ><ImCross /></span>
                    <img className="modal-content" id="img01" src={Layoutplan} />
                    <div id="caption">Lay Out Plan</div>
                </div>
            </div>
            <div className='video-sec'>
                <YoutubeEmbed embedId="4BEeKjL1umc" />
            </div>
        </>
    );
}

export default LayoutPlan;