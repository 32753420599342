import React from 'react';
import "./HouseSlider.css";
import pic1 from "./Images/mid1.jpg";
import pic2 from "./Images/mod1.jpg";
import pic3 from "./Images/mid7.jpg";
import pic4 from "./Images/mod7.jpg";
import pic5 from "./Images/mid5.jpg";
import pic6 from "./Images/mod5.jpg";
import pic7 from "./Images/mid10.jpg";
import pic8 from "./Images/mod10.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaBed, FaShower, FaHome } from 'react-icons/fa';

const HouseSlider = () => {

    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 700,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1180,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }
    return (
        <>

            <center style={{marginTop:"50px"}}>
                <h1 style={{ color: "#1A5257" }}>Explore Our Properties</h1>
                <h5 style={{ color: "#931B1F",fontSize:"16px" }}>Your Dream Your House, We Make It Real  </h5>
            </center>
            <div style={{ width: "100%" }}>

                <div className="blogpost" >
                    <Slider {...settings}  >

                        <div className="Slider77-sec">
                            <div className="image-secc" >
                                <img className="cont-imaaag" src={pic1} />
                            </div>
                            <div className='text-sec'>
                                <h3  >
                                    1 Kanal Mediterranean
                                </h3>
                                <div className='text-last-sce'>
                                    <div>
                                        <FaBed />
                                        <p> 3 Bed Room</p>
                                    </div>
                                    <div>
                                        <FaShower />
                                        <p>2 Washroom</p>
                                    </div>
                                    <div>
                                        <FaHome />
                                        <p> 400 x 400</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Slider77-sec">
                            <div className="image-secc">
                                <img className="cont-imaaag" src={pic2} />
                            </div>
                            <div className='text-sec'>
                                <h3 >
                                    1 Kanal Modern
                                </h3>
                                <div className='text-last-sce'>
                                    <div>
                                        <FaBed />
                                        <p> 3 Bed Room</p>
                                    </div>
                                    <div>
                                        <FaShower />
                                        <p>2 Washroom</p>
                                    </div>
                                    <div>
                                        <FaHome />
                                        <p> 400 x 400</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Slider77-sec">
                            <div className="image-secc" >
                                <img className="cont-imaaag" src={pic7} />
                            </div>
                            <div className='text-sec'>
                                <h3  >
                                    10 Marla Mediterranean
                                </h3>
                                <div className='text-last-sce'>
                                    <div>
                                        <FaBed />
                                        <p> 3 Bed Room</p>
                                    </div>
                                    <div>
                                        <FaShower />
                                        <p>2 Washroom</p>
                                    </div>
                                    <div>
                                        <FaHome />
                                        <p> 400 x 400</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Slider77-sec">
                            <div className="image-secc">
                                <img className="cont-imaaag" src={pic8} />
                            </div>
                            <div className='text-sec'>
                                <h3 >
                                    10 Marla Modern
                                </h3>
                                <div className='text-last-sce'>
                                    <div>
                                        <FaBed />
                                        <p> 3 Bed Room</p>
                                    </div>
                                    <div>
                                        <FaShower />
                                        <p>2 Washroom</p>
                                    </div>
                                    <div>
                                        <FaHome />
                                        <p> 400 x 400</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Slider77-sec">
                            <div className="image-secc">
                                <img className="cont-imaaag" src={pic3} />
                            </div>
                            <div className='text-sec'>
                                <h3 >
                                    7 Marla Mediterranean
                                </h3>
                                <div className='text-last-sce'>
                                    <div>
                                        <FaBed />
                                        <p> 3 Bed Room</p>
                                    </div>
                                    <div>
                                        <FaShower />
                                        <p>2 Washroom</p>
                                    </div>
                                    <div>
                                        <FaHome />
                                        <p> 400 x 400</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Slider77-sec">
                            <div className="image-secc">
                                <img className="cont-imaaag" src={pic4} />
                            </div>
                            <div className='text-sec'>
                                <h3 >
                                    7 Marla Modern
                                </h3>
                                <div className='text-last-sce'>
                                    <div>
                                        <FaBed />
                                        <p> 3 Bed Room</p>
                                    </div>
                                    <div>
                                        <FaShower />
                                        <p>2 Washroom</p>
                                    </div>
                                    <div>
                                        <FaHome />
                                        <p> 400 x 400</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="Slider77-sec">
                            <div className="image-secc">
                                <img className="cont-imaaag" src={pic5} />
                            </div>
                            <div className='text-sec'>
                                <h3 >
                                    5 Marla Mediterranean
                                </h3>
                                <div className='text-last-sce'>
                                    <div>
                                        <FaBed />
                                        <p> 3 Bed Room</p>
                                    </div>
                                    <div>
                                        <FaShower />
                                        <p>2 Washroom</p>
                                    </div>
                                    <div>
                                        <FaHome />
                                        <p> 400 x 400</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Slider77-sec">
                            <div className="image-secc">
                                <img className="cont-imaaag" src={pic6} />
                            </div>
                            <div className='text-sec'>
                                <h3 >
                                    5 Marla Modern
                                </h3>
                                <div className='text-last-sce'>
                                    <div>
                                        <FaBed />
                                        <p> 3 Bed Room</p>
                                    </div>
                                    <div>
                                        <FaShower />
                                        <p>2 Washroom</p>
                                    </div>
                                    <div>
                                        <FaHome />
                                        <p> 400 x 400</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>

        </>
    );
}

export default HouseSlider;