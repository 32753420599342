import React from 'react';
import slider1 from "./Images/slider1.jpg"
import slider2 from "./Images/slider2.jpg"
import slider3 from "./Images/slider3.jpg"
import Carousel from 'react-bootstrap/Carousel';


const Slider = () => {
    return (
        <>
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slider1}
                        alt="First slide"
                    />
                   
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slider2}
                        alt="Second slide"
                    />

                    
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slider3}
                        alt="Third slide"
                    />

                    
                </Carousel.Item>
            </Carousel>
        </>
    );
}
export default Slider;