import React from 'react';
import './AboutDinPro.css';
import logogif from './Images/DinGardens.gif';
// import bgimag from './Images/WELCOME-01.jpg'

const AboutDinPro = () => {
    return (
        <>
            <div className='mt-5 d-flex justify-content-center'>
                <h1 > <span style={{ color: "#1A5257" }} >WELCOME TO </span> <span style={{ color: "#931B1F" }}>DIN GARDENS </span></h1>
            </div>
            <div className="bggg-image"  >

                <div className="bgg-text-sec">
                    <div className='bgg-text'>
                        <h4>About Din Properties</h4>
                        <p>
                            Din Properties is a recent venture of Din Industries aimed at providing quality real estate to our valued customers. With years of service, Din Properties is a name that you can trust.
                        </p>
                        <p>
                            Din Properties is committed to quality, innovation and excellent customer service that builds trust and empowers respect with our clients. With a team of committed and hard working people we make sure that your investment with Din Properties be your best decision.
                        </p>
                    </div>
                </div>
                <div className="logo-secc">
                    <img src={logogif} />
                </div>
            </div>

        </>
    );
}

export default AboutDinPro;