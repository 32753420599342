import React from 'react';
import './OurTeam.css';
import dealor1 from './Images/dealor-01.jpg'
import dealor2 from './Images/dealor-02.jpg'
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';
import { MdOutgoingMail, MdAccountCircle } from 'react-icons/md';
import { IoMdCall } from 'react-icons/io';

const OurTeem = () => {
    return (
        <>
            <div className='my-5'>
                <center>
                    <h1 style={{ color: "#1A5257" }}>MEET OUR AUTHORIZED DEALERS</h1>
                    <h5 style={{ color: "#931B1F", fontSize: "16px" }}>You are only an email away from our support</h5>
                </center>
                <div className='team-sec'>
                    <div className='team-sec1'>
                        <div className='image-sec'>
                            <img src={dealor1} />
                        </div>
                        <div className='dealor-profile'>
                            <h3>Rising Estates</h3>
                            <h5>CEO</h5>
                            <p><MdAccountCircle /> Yousaf Usman</p>
                            <p><IoMdCall /> +92333-6589911</p>
                            <p><MdOutgoingMail /> info@dinproperties.com.pk</p>
                            <div className='social-media-sec'>
                                <a herf="#"
                                    className="facebook "
                                    role="button"
                                ><FaFacebookF /></a>

                                <a herf="#"
                                    className="twiter "
                                    role="button"
                                ><FaTwitter /></a>

                                <a herf="#"
                                    className="insta "
                                    role="button"
                                ><FaInstagram /></a>

                                <a herf="#"
                                    className=" youtubee"
                                    role="button"
                                ><FaYoutube /></a>
                            </div>
                            <div className='button-secc'>
                                <button type='button' className=" prfile-bottn  ">
                                    View Profile
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className='team-sec2'>
                        <div className='image-sec'>
                            <img src={dealor2} />
                        </div>
                        <div className='dealor-profile1'>
                            <h3>Tahir Maan’s & Company</h3>
                            <h5>CEO</h5>
                            <p><MdAccountCircle /> Tahir Ismail Gill </p>
                            <p><IoMdCall /> +92300-7122000</p>
                            <p><MdOutgoingMail /> info@dinproperties.com.pk</p>
                            <div className='social-media-sec'>
                                <a herf="#"
                                    className="facebook "
                                    role="button"
                                ><FaFacebookF /></a>

                                <a herf="#"
                                    className="twiter "
                                    role="button"
                                ><FaTwitter /></a>

                                <a herf="#"
                                    className="insta "
                                    role="button"
                                ><FaInstagram /></a>

                                <a herf="#"
                                    className=" youtubee"
                                    role="button"
                                ><FaYoutube /></a>
                            </div>
                            <div className='button-secc'>
                                <button type='button' className=" prfile-bottn  ">
                                    View Profile
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </>
    );
};

export default OurTeem;